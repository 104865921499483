*{
    margin:0;
    padding:0;
}


.slider{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
}

.imagesSlide{
    width: 80%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

@keyframes fade-in {
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}

.arrows{
    width: 5%;
    transition: 0.4s;
}

.arrows:hover{
    transform: scale3d(1.1,1.1,1.1);
    cursor: pointer;
}
