
.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    justify-content: space-evenly;

    width: 100vw;
    height: 90vh;
}

.about h1{
    font-family: 'Poppins' !important;
    font-size: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #146C94;
}

.about .members{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 80%;
    min-height: 50%;
}


@media (max-width: 850px) {

    .about h1{
        font-size: 35px;
    }

    .about .members{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 80%;
    }
}