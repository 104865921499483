.member{
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 2%;
    border-radius: 3%;

    background-color: #f5f5f5;
    
    box-shadow: 0 7px 15px #c9c4c4;

    height: 70%;
    width: 35%;

    justify-content: space-evenly;
}

.vertical-align{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    height: 70%;
}

.vertical-align img{
    max-width: 250px;
    max-height: 250px;
    border-radius: 50%;
    box-shadow: 0 7px 15px #c9c4c4;
    
}

.vertical-align h3{
    text-align: center;
    text-transform: uppercase;
    color:#146C94
}

.member p {
    font-family: 'Roboto';
}

#exp_years{
    color:#19A7CE;
    text-align: center;
}

#title{
    text-align: center;
    font-weight: normal;
    color: #19A7CE;
}

#description {
    font-family: 'Roboto';
    font-weight:normal;
    text-align: justify;
    color:black;
    line-height: 1.5;
    height: 70%;
    width: 35%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 1600px) {
    #description{
        display: none;
    }
    .vertical-align{
        width: 100%;
    }
}

@media (max-width: 850px){
    .member{
        width: 70%;
        height: 30vh;
    }

    

    .vertical-align img{
        max-width: 150px;
    }
}