.responsive-links{
    display: none;
}

.header{
    position:sticky;
    top:0;
    z-index: 10;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;

    height: 10vh;
    width: 100%;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    overflow-x: hidden;
}

.header h1{
    margin-left:5%;
}

.header h1 a{
    cursor:pointer;
    color: #146C94;
    text-decoration: none;
}

.header a h1:hover{
    cursor: pointer;
}

.links-icon{
    width: 45%;
    height: 100%;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.header-links{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    align-items:center;
}

.header-links ul{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    height: 100%;
    align-items: center;
    width: 75%;
}

.header-links ul li{
    display: flex;
    align-items: center;
    text-transform: uppercase;
    list-style-type: none;
    height: 100%;
}

.header-links ul li a{
    transition: border-bottom 0.5s ease-in;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    color:black;
}

.header-links ul li a:hover{
    cursor: pointer;
    color: #146C94;
    border-bottom: 1px solid #146C94;
}

@keyframes border-bottom-fade {
    0%{
        border-bottom: none;
    }
    100%{
        border-bottom: 1px solid #146C94;
    }
}


.header-links select{
    width: 100px;
    text-align: center;
    padding: 10px;
    font-size: medium;
}

.header-links select option{
    text-align: left;
}

#menu{
    display:none;
}

.header img{
    margin-right: 5px;
}


/* --- Responsive --- */


@media (max-width: 1500px) {
    #menu{
        display: block;
        width: 30px;
    }

    .header{
        position:sticky;
        top:0;
        z-index: 200;
    }

    .header-links{
        display: none;
        width: 0%;
    }

    .links-icon{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
    }

    .header-links-active{
        display:none;
    }

    .responsive-links-active{
        z-index: 150;
        display:inline;
        position: fixed;
        width: 100vw;
        height: 90vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        background-color: rgba(255, 255, 255, 0.904);
    }
    
    .responsive-links-active ul{
        height: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .responsive-links-active ul li{
        display: flex;
        align-items: center;
        text-transform: uppercase;
        list-style-type: none;
        height: 100%;
        font-size: 20px;
    }
    
    .responsive-links-active ul li a{
        transition: border-bottom 0.5s ease-in;
        border-bottom: 1px solid transparent;
        text-decoration: none;
        color:black;
    }
    
    .responsive-links-active ul li a:hover{
        cursor: pointer;
        color: #146C94;
        border-bottom: 1px solid #146C94;
    }

    .responsive-links-active select{
        width: 100px;
        text-align: center;
        padding: 10px;
        font-size: medium;
}

    .responsive-links-active select option{
        text-align: left;
    }
}

@media (max-width: 850px) {
    .responsive-links-active{
        height: 100vh;
        z-index: 150;
    }

    .header h1{
        width: 80%;
    }

    .header h1 a{
        font-size: 70%;
        width: 100%;
    }

}
