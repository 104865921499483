
.home{
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: row;
    
    justify-content:space-evenly;
    align-items: center;
}

.home-texts{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* height: 50%; */
}

.home-slider{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


.home h1{
    width: 60%;
    font-family: 'Poppins' !important;
    font-size: 45px;
    text-align: center;
    text-transform: uppercase;
    color: #146C94;
}

.home p{
    width: 60%;
    line-height: 1.7;
    text-align: justify;
    font-family: 'Roboto';
}


@media (max-width: 1500px){
    .home{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        width: 100vw;
        height: 90vh;
    }

    .home-texts{
        width: 100%;
        
    }

    .home-slider{
        width: 50%;
        
    }
}

@media (max-width: 850px) {
    .home{
        width: 100vw;
        height: 90vh;
    }

    .home-texts h1{
        width: 90%;
        font-size: 35px;
    }

    .home-texts p{
        width: 90%;
    }

    .home-texts{
        
    }

    .home-slider{
        width: 95%;
    }
}

@media (max-width: 350px){
    .home-texts h1{
        width: 95%;
        font-size: 25px;
    }
}