.location{
    width: 100vw;
    height: 90vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


.location-iframe{
    width: 30%;
    display: flex;
    justify-content: center;
}

.location-iframe iframe{
    border: 1px solid black;
    box-shadow: 0 7px 15px #c9c4c4;
}

.location-text{
    width: 30%;
    height: 35%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-evenly
}

.location-text h1{
    font-size: 45px;
    font-family: 'Poppins' !important;
    text-transform: uppercase;
    color:#146C94;
}

.location-text p{
    font-family: 'Roboto';
}

#schedule{
    text-align: center;
    line-height: 1.5;
}

#location-description{
    color: #19A7CE;
}

.location-iframe-responsive{
    display: none;
}

.location-iframe-responsive iframe{
    border: 1px solid black;
    box-shadow: 0 7px 15px #c9c4c4;
}

@media (max-width: 850px) {
    .location{
        flex-direction: column;
    }

    .location-iframe{
        display: none;
        width: 0%;
    }

    .location-text{
        width: 90%;
        height: 40%;
    }

    .location-text h1{
        font-size: 25px;
    }

    .location-iframe-responsive{
        display: flex;
        justify-content: center;
        width: 80%;
        height: 60%;
    }
}