@font-face {
    font-family: 'Montserrat';
    src: url("./assets/Montserrat-Regular.ttf");
}

@font-face {
    font-family: 'Roboto';
    src: url("./assets/Roboto-Regular.ttf");
}

@font-face {
    font-family: 'Poppins';
    src: url("./assets/Poppins-Regular.ttf");
}

* {
    font-family: 'Montserrat', 'Roboto', 'Poppins' !important;
    margin: 0;
    padding: 0;
}

html{
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.space-section{
    height: 10vh;
}