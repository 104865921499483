.services{
    width: 100vw;
    height: 90vh;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-evenly;
}

.services::before{
    content: "";
    background-image: url('../assets/offer.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.25;
}

.services-text{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-text h1{
    position: relative;
    width: 100%;
    font-size: 45px;
    font-family: 'Poppins' !important;
    text-transform: uppercase;
    color:#146C94;
    text-align: center;
}

.services-services{
    position: relative;
    width: 40%;
    height: 70%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-evenly;
}

.service {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    padding: 20px;
    background-color: transparent;
    border: 1px solid black;
    width: 500px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.438);
}

.service:hover {
    cursor: pointer;
}

.service h3{
    text-align: center;
    color: black;
    font-family: 'Roboto';
    text-transform: uppercase;
}

.service p{
    text-align: justify;
    font-family: 'Roboto';
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.5;
}

.description-hidden{
    transition: 0.5s;
    display: none;
}

.description-shown{
    display:auto;
    transition: 0.5s;
}


@media (max-width: 1050px){
    .services{
        flex-direction: column;
    }
}

@media (max-width: 850px){
    .services-text h1 {
        font-size: 25px;
    }

    .services-text{
        width: 90%;
        height: 10%;
    }

    .services-services{
        width: 100%;
        height: 90%;
    }

    .service{
        width: 80%;
    }

    .service h3{
        font-size: 14px;
    }
}