.contact{
    width: 100vw;
    height: 90vh;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.contact-text{
    width: 50%;
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.contact-text h1{
    font-size: 45px;
    font-family: 'Poppins' !important;
    text-transform: uppercase;
    color:#146C94;
}

.contact-text p{
    font-family: 'Roboto';
}

.contact-images{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
    margin-top: 50px;
}

.contact-images a img{
    width: 40px;
    transition: 0.5s;
}

.contact-images a img:hover{
    cursor: pointer;
    transform: scale3d(1.1,1.1,1.1);
}

.contact-form{
    width: 50%;
    height: 70%;    
}

.contact-form form{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.contact-form input{
    width: 50%;
    background-color: transparent;
    outline: none;
    border: 1px solid #146C94;
    color: black;
    padding: 20px;
    font-size: 17px;
    transition: 0.5s;
    font-family: 'Roboto';
}

.contact-form input::placeholder{
    background-color: transparent;
    border: transparent;
    color: black;
    font-size: 17px;
}

.contact-form textarea {
    background-color: transparent;
    outline: none;
    border: 1px solid #146C94;
    color: black;

    width: 50%;
    min-width: 50%;
    max-width: 50%;
    height: 35%;
    min-height: 35%;
    max-height: 35%;

    padding: 20px;
    font-size: 17px;
    transition: 0.5s;
    font-family: 'Roboto';
}

.contact-form textarea::placeholder {
    background-color: transparent;
    border: transparent;
    color: black;
}

.contact-form textarea:focus {
    box-shadow: 5px 5px 5px #146C94;
}

.contact-form input:focus {
    box-shadow: 5px 5px 5px #146C94;
}

.contact-form button{
    width: 150px;
    padding: 20px;
    font-family: 'Roboto';
    background-color: transparent;
    border: 1px solid #146C94;
    color: #146C94;
    transition: 0.7s;
    text-transform: uppercase;
}

.contact-form button:hover{
    cursor: pointer;
    background-color: #146C94;
    color: white;
}

@media (max-width: 850px) {
    .contact{
        flex-direction: column;
    }

    .contact-text{
        width: 90%;
        height: 30%;
    }

    .contact-form{
        width: 100%;
        height: 70%;
    }

    .contact-text h1{
        font-size: 25px;
        text-align: center;
    }

    .contact-form input{
        width: 80%;
        font-size: 14px;
    }
    
    .contact-form input::placeholder{
        font-size: 14px;
    }
    
    .contact-form textarea {
    
        width: 80%;
        min-width: 80%;
        max-width: 80%;
    
        padding: 20px;
        font-size: 14px;
        transition: 0.5s;
        font-family: 'Roboto';
    }

    .contact-images{
        width: 80%;
    }

    .contact-text p{
        text-align: center;
    }
}