.footer{
    background-color: rgb(28, 26, 26);
    box-shadow: 0px -2px 2px rgb(28, 26, 26);
    color: white;
    width: 100vw;
    height: 15vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.5px;
}

.footer a{
    color: pink;
    text-decoration: none;
}